import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { MethodsService } from '../../services/methods/methods.service'
import { LoggerService } from '../../services/logger/logger.service'
import { CustomerConfigService } from '../../services/api/services/customer-config.service'
import { GlobalNotification, GlobalState } from '../../app.state'
import JSONEditor from 'jsoneditor'

@Component({
  selector: 'pi-customer-config',
  templateUrl: './customer-config.component.html',
  styleUrls: ['./customer-config.component.scss']
})

export class PICustomerConfigComponent extends TableWithFilters implements OnInit, OnDestroy {

  readonly title = 'PI Tenants Configs Management'

  filterValue: string = ''

  customers: CustomersByEnv[] = []
  filteredCustomers: CustomersByEnv[] = []
  defaults: Record<keyof EditableCustomer, any>

  collectedFullData: boolean = false
  readonly SensitiveAreaDetectionMode = {
    0 : "CONFIGURATION_ONLY",
    1 : "CONFIGURATION_AND_AUTOMATED",
    2 : "CONFIGURATION_AND_AUTOMATED_HYBRID"
  }

  //view
  selectedCustomer?: CustomersByEnv

  //edit
  selectedCustomerEdit?: CustomerWithConfig
  selectedCustomerConfig?: EditableCustomer
  selectedCustomerEditor?: JSONEditor

  //filters
  availableConfigTypes: { id: string, text: string }[] = [
    //@formatter:off
    { id: 'allConfig',  text: 'Has config'       },
    { id: 'matching',   text: 'Updated'          },
    { id: 'hasDiff',    text: 'Update Available' },
    { id: 'noConfig',   text: 'No config'        },
    //@formatter:on
  ]
  currentConfigType: { id: string, text: string }[] = []

  private _debounce: void | number
  private debounce_timeout: number = 200

  //table
  readonly tableHeaders: TableHeader[]

  currentSortBy: string

  paginationName = 'Customers With Configs'

  _isRefreshing: boolean = false
  numOfAllDocs: number = 0

  textToClip = MethodsService.copyToClipboard

  normalizeString = MethodsService.normalizeString

  isTruthy(value: any): boolean {
    return value !== null && value !== undefined;
  }

  constructor (private _configs: CustomerConfigService, private _zone?: NgZone, private _state?: GlobalState) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      { /*width: '8', */    name: 'Created At',         sortable: true, field: '$staging.createdAt'    },
      { /*width: '8', */    name: 'Last Modified',      sortable: true, field: '$production.updatedAt' },
      { /*width: '12',*/    name: 'Tenant ID',          sortable: true, field: '$staging._id'          },
      { /*width: '12',*/    name: 'Tenant Name',        sortable: true, field: '$staging.name'         },
      { /*width: '12',*/    name: 'Tenant Website',     sortable: true, field: '$staging.website'      },
      { /*width: '12',*/    name: 'Staging Config',     sortable: false                                },
      { /*width: '12',*/    name: 'Production Config',  sortable: false                                },
      { /*width: '12',*/    name: 'Status',             sortable: false                                },
      { /*width: '12',*/    name: 'Actions',            sortable: false                                }
      //@formatter:on
    ].filter(x => !!x)
    this.currentSortBy = this.tableHeaders[1].field
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedCustomer && !this.selectedCustomerEdit) {
      return
    }
    if (key === 'Escape') {
      this.resetView()
      this.resetEditor()
    }
  }

  onFilterChange () {
    if (this._debounce) {
      clearTimeout(this._debounce)
    }

    this._debounce = setTimeout(() => this.refreshTable(true,true), this.debounce_timeout) as any as number
  }

  async refreshTable (background = false, fetchFullData = false, hardRefresh = false): Promise<void> {
    if(hardRefresh) {
      this.collectedFullData = false
    }
    let shouldEarlyExit = false
    if (!fetchFullData && this._isRefreshing) {
      return
    }
    if(this.currentConfigType[0]?.id){
      fetchFullData = true
    }
    this._isRefreshing = true
    try {
      if (!background && !fetchFullData) {
        this.selectedCustomer = undefined
        this.resetEditor()
        SpinnerService.spin('mini')
      }

      if(!fetchFullData && this.collectedFullData) {
        fetchFullData = true
      }

      let stagingData: CustomerWithConfig[]
      let productionData: CustomerWithConfig[]

      if (!this.collectedFullData) {
        const options = fetchFullData ? { limit: Number.MAX_SAFE_INTEGER } : undefined
        const [customersConfigs, defaults] = await Promise.all([
          this.getCustomersConfigs(options),
          this.defaults ? Promise.resolve(this.defaults) : this._configs.getDefaults()
        ])
        const { staging, production } = customersConfigs.data
        const stagingCount = staging.count
        stagingData = staging.data
        const productionCount = production.count
        productionData = production.data
        if (fetchFullData) {
          this.collectedFullData = true
        }
        this.defaults = defaults

        this.numOfAllDocs = stagingCount
        //validate
        if (!fetchFullData && stagingData.some((c, i) => c._id !== productionData[i]._id)) {
          LoggerService.info('stagingData:', stagingData)
          LoggerService.info('productionData:', productionData)
          LoggerService.info(`stagingCount: ${stagingCount}, productionCount: ${productionCount}`)
          //   throw Error('Corrupted Data!')
          LoggerService.warn('Corrupted data detected. fetching full production data!')
          await this.refreshTable(background, true)
          shouldEarlyExit = true
          return
        }
        this.numOfAvailableDocs = stagingCount
      } else if(this.customers.length == this.numOfAllDocs) {
        this.sortTable()
        return
      }

      if (stagingData && productionData) {
        if (fetchFullData || this.collectedFullData) {
          this.customers = stagingData.map((stagingConfig) => {
            const matchingProd = productionData.find(prodConfig => prodConfig._id == stagingConfig._id)
            return {
              staging: { ...stagingConfig, customerConfig: this.fillDefaults(stagingConfig.customerConfig) },
              production: { ...matchingProd, customerConfig: this.fillDefaults(matchingProd.customerConfig) },
              ...this.canUpdateCustomer(stagingConfig, matchingProd)
            }
          })
          this.sortTable()
        } else {
          this.customers = stagingData.map((c, i) => ({
            staging: { ...c, customerConfig: this.fillDefaults(c.customerConfig) },
            production: { ...productionData[i], customerConfig: this.fillDefaults(productionData[i].customerConfig) },
            ...this.canUpdateCustomer(c, productionData[i])
          }))
          this.filteredCustomers = this.customers
        }

        if (!background) {
          LoggerService.info(this.filteredCustomers)
        }
      } else {
        throw Error('Bad Response')
      }
    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching customers', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!shouldEarlyExit) {
        if (!background) {
          SpinnerService.stop('mini')
        }
        this.ref_hljs(this._zone)
        this._isRefreshing = false
      }
    }
  }

  sortTable () {
    this.filteredCustomers = [...this.customers]

    if (this.currentConfigType[0]?.id) {
      if (this.currentConfigType[0].id == 'allConfig') {
        this.filteredCustomers = this.filteredCustomers.filter(c => (!!c?.staging?.customerConfig || c?.production?.customerConfig))
      } else if (this.currentConfigType[0].id == 'noConfig') {
        this.filteredCustomers = this.filteredCustomers.filter(c => (!c?.staging?.customerConfig && !c?.production?.customerConfig))
      }
      else {
        this.filteredCustomers = this.filteredCustomers.filter(c => this.currentConfigType[0].id == 'hasDiff' ? c.updateAvailable : !c.updateAvailable && !!c.staging.customerConfig && !!c.production.customerConfig)
      }
    }

    let filterSortBy: string
    filterSortBy = this.currentSortBy.replace('$','')
    const searchFields = ['staging.CustomerID', 'staging.akamaiCustomerId', 'staging._id', 'staging.akamaiAccountName', 'staging.name', 'staging.website', 'staging.createdAt', 'production.updatedAt', 'production.clusterId', 'production.domains']
    this.filteredCustomers = this.sortTableData(this.filteredCustomers, filterSortBy, this.isSortReversed, this.filterValue, searchFields)
    this.numOfAvailableDocs = this.filteredCustomers.length
    this.filteredCustomers = this.filteredCustomers.slice((this.currentPage - 1) * this.currentItemsPerPage, this.currentItemsPerPage * this.currentPage)
  }

  searchTable () {
    this.currentPage = 1
    if(this.collectedFullData) {
      return this.sortTable()
    }
    void this.refreshTable()
  }

  async viewConfig (customerId: string) {
    try {
      SpinnerService.spin('mini')
      const customer = await this._configs.getCustomer(customerId)
      if (customer) {
        this.selectedCustomer = {
          staging: { ...customer.staging, customerConfig: this.fillDefaults(customer.staging.customerConfig) },
          production: { ...customer.production, customerConfig: this.fillDefaults(customer.production.customerConfig) },
          ...this.canUpdateCustomer(customer.staging, customer.production)
        }
        LoggerService.info(this.selectedCustomer)
      }
    } catch (e) {
      MethodsService.toast('error', 'Error fetching customer ' + customerId, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this.ref_hljs(this._zone)
    }
  }

  async editCustomerConfig (customerId: string) {
    try {
      SpinnerService.spin('mini')
      const customerConfig = await this._configs.getCustomer(customerId)

      if (!customerConfig) {
        throw Error('Failed retrieving customer config!')
      }

      customerConfig.staging.customerConfig = this.fillDefaults(customerConfig.staging.customerConfig)

      const indexOf = this.customers.indexOf(this.customers.find(c => c.staging._id == customerId)!)

      this.customers[indexOf] = {
        ...customerConfig,
        ...this.canUpdateCustomer(customerConfig.staging, customerConfig.production)
      }

      const conf = customerConfig.staging
      this.selectedCustomerEdit = conf
      this.selectedCustomerConfig = { ...conf.customerConfig }
      ;['_id', 'customerId', 'createdAt', 'updatedAt', '__v'].forEach(atr => delete this.selectedCustomerConfig[atr])
      setTimeout(() => {
        const container = document.getElementById('jsoneditor')
        this.selectedCustomerEditor = new JSONEditor(container)
        this.selectedCustomerConfig = this.fillDefaults(this.selectedCustomerConfig)
        this.selectedCustomerEditor.set(this.selectedCustomerConfig)
      }, 10)

    } catch (e) {
      MethodsService.toast('error', 'Error fetching customer ' + customerId, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }
  }

  resetView () {
    this.selectedCustomer = undefined
  }

  resetEditor () {
    this.selectedCustomerEdit = undefined
    this.selectedCustomerConfig = undefined
    this.selectedCustomerEditor = undefined
  }

  canUpdateStagingConfig (): boolean {
    const customerId = this.selectedCustomerEdit._id
    if (!this.selectedCustomerEditor) {return false}
    const customerConfig = this.selectedCustomerEditor.get()
    const { updateAvailable } = this.canUpdateCustomer({ customerConfig } as CustomerWithConfig, this.customers.find(c => c.staging?._id == customerId)!.staging)
    return updateAvailable
  }

  async saveStagingConfig () {
    try {
      SpinnerService.spin('mini')
      const success = await this._configs.modifyStagingConfig(this.selectedCustomerEdit._id, this.selectedCustomerEditor.get())
      if (success) {
        MethodsService.toast('success', 'Success', `Tenant ${this.selectedCustomerEdit._id} staging config saved successfully`, 8)
        await this.refreshTable!()
        this.resetEditor()
      }
    } catch (e) {
      MethodsService.toast('error', 'Error saving staging config', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this.ref_hljs(this._zone)
    }
  }

  async pushStageToProd () {
    try {
      SpinnerService.spin('mini')
      const success = await this._configs.setCustomerConfigFromStaging(this.selectedCustomer.staging._id)
      if (success) {
        MethodsService.toast('success', 'Success', `Tenant ${this.selectedCustomer.staging._id} staging config pushed successfully to production`, 8)
        await this.refreshTable!()
        this.resetView()
      }
    } catch (e) {
      MethodsService.toast('error', 'Error setting prod config', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }
  }

  canUpdateCustomer (staging: CustomerWithConfig, production: CustomerWithConfig): { updateAvailable: boolean, changedFields?: string[] } {
    const result: { updateAvailable: boolean, changedFields?: string[] } = { updateAvailable: false }

    if (!staging.customerConfig) {
      return result
    }

    if (staging.customerConfig && !production.customerConfig) {
      result.updateAvailable = true
      return result
    }

    result.changedFields = []

    const stagingJson: Partial<CustomerConfigSchema> = {}
    const productionJson: Partial<CustomerConfigSchema> = {}

    for (const key in this.defaults) {
      if (!(key in staging.customerConfig) || staging.customerConfig[key] === undefined || staging.customerConfig[key] === null) {
        stagingJson[key] = this.defaults[key]
      } else {
        stagingJson[key] = staging.customerConfig[key]
      }

      if (production.customerConfig) {
        if(!(key in production.customerConfig) || production.customerConfig[key] === undefined || production.customerConfig[key] === null) {
          productionJson[key] = this.defaults[key]
        } else {
          productionJson[key] = production.customerConfig[key]
        }
      }

      if (!MethodsService.objectsAreEqual(stagingJson[key], productionJson[key])) {
        result.changedFields.push(key)
        result.updateAvailable = true
      }
    }

    return result
  }

  fillDefaults<T extends IfDefined<EditableCustomer>> (conf: T): T {
    if (!conf) {
      return conf
    }
    for (const key in this.defaults) {
      if (!(key in conf) || conf[key] === undefined || conf[key] === null) {
        conf[key] = this.defaults[key]
      }
    }
    return conf
  }

  private async getCustomersConfigs (options?: FilterArguments): Promise<ResultObject<CustomerConfigsResponse>> {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy.replace(/^\$\w+\./i, ''),
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    }
    return await this._configs.getCustomersConfigs(options)
  }

  protected readonly Object = Object
}
